// Angular
import { InjectionToken } from '@angular/core';
// NgRx Store
import { StoreConfig, createReducer, on, createAction, props, createSelector } from '@ngrx/store';
// Constants
import { REGNO_COUNTRIES } from '@constants';

// Meta-reducer configuration token
export const FEATURE_REGNR_TOKEN = new InjectionToken<StoreConfig<any>>('Regnr Feature Token');

// Token for the local storage keys.
export const REG_NR_LS_KEY = new InjectionToken<string[]>('Regnr LocalStoreKeys');

// SELECTOR
export const selectRegnrState = (state: any) => state.regnr
export const currentRegnr = createSelector(selectRegnrState, regnr => regnr);


// ACTIONS
export const saveRegNr = createAction(
    '[Regnr] Save',
    props<{ country: any; plate: any }>()
)

// INTERFACE
export interface RegAppState {
    country: {
        id: number,
        code: string;
        country: string;
        flag: string;
    };
    plate: string;
}

// INITIAL STATE
export const initialState: RegAppState = {
    country: REGNO_COUNTRIES[0],
    plate: '',
}

// REDUCER
export const regnrReducer = createReducer(
    initialState,
    on(saveRegNr, (state, action) => ({ ...state, country: action.country, plate: action.plate }))
)